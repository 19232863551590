export default [
  {
    title: 'Helpdesk',
    icon: 'HelpCircleIcon',
    route: 'pages-helpdesk',
    minAccess: 1,
    maxAccess: 1001,
  },
  {
    title: 'FAQ',
    icon: 'BookOpenIcon',
    route: 'pages-faq',
    minAccess: 1,
    maxAccess: 1001,
  },
]
